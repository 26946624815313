.content-wrapper{
    min-height: 100vh;
    /* border:1px solid black; */
    margin-left: 220px;
    padding: 100px 30px 15px 30px;
    /* background-color: #f5f5f5; */
    /* background-color: #f8f8f8 */
}

.toast-wrapper{
    border-radius: 50px!important;
    background-color: #C7EBFA!important;
    text-align:center;
    padding:0!important;
    width:100!important
}
.toast-text{
    color:#00A1ED!important;
    font-size: 16px;
    margin-left: 100px;
    font-weight: bold;
    padding:0!important

}