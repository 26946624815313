.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-profile{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 10px;
    z-index: 5000;
    margin-top: 20px;
    margin-right: 20px;
    /* height: 200px; */
    position: fixed!important;
    padding:10px
}
/* .ck-editor__editable:not(.ck-editor__nested-editable) {
    max-height: 400px;
} */
.modal-content{
    /* width:400px; */
    outline: 0px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    

}
.modal-header{
    padding: 0px 20px 0px 20px;
    
    /* height: 50px; */;
    display: flex;
    justify-content: space-between;
    /* border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium; */
}
.modal-block{
    width: 100%;
    height: 1px;
    /* dashed border */
    margin-bottom: 20px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23CCCCCCFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
}
.modal-header img{
    cursor: pointer;
}
.modal-header h2{
    font-family: 'Lato', sans-serif;
    color: #252525;
    font-size: 20px;
}
.modal-body{
    /* width: 50%; */
    padding-left:20px;
    padding-right:20px;
    padding-bottom:10px;
    /* padding-top:10px; */
    min-height: 100px;
    /* max-height: 450px;
    overflow-y: auto; */
}
.modal-content .modal-hr{
    border:1px dashed  #ccc;
}
.modal__field{
    width: 100%;
}


.profile-container{
    /* border:1px solid black */
    text-align: center;
}
.profile-container h2{
    font-family: Open Sans, sans-serif;
    color: #252525;
    font-size: 18px;
    margin-bottom: 0;
}
.profile-container p{
    font-family: Open Sans, sans-serif;
    color: #777777;
    font-size: 14px;
    margin-top: 0;

}

.rm-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rm-filter-by{
    display: flex;
    align-items: center;
    margin-top: 0px!important;

}
.rm-filter-by p{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
}
.rm-filter-field{
    width: 170px;
}
.contact-field{
    width: 140px;
}
.modal-footer{
    display: flex;
    justify-content: flex-end;
    padding-bottom:20px
}
.rm-table{
    max-height: 300px;
    overflow-y: scroll;
}
.rm-table::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .rm-table {
    -ms-overflow-style: none;
  }

  .confirm-container,.alert-container{
    padding:10px;
    text-align: center;
}
.card-footer{
    display: flex;
    justify-content: flex-end;
    padding:20px
}
.p-filter{
    font-size: 12px;
    margin:0;
    color: #252525;
}
.p-filter2{
    font-size: 12px;
    margin:0;
    color: #777777;
    font-weight: bold;
}
.participant-modal{
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.intruksi-modal{
    max-height: 550px;
    overflow-y: auto;
}
.intruksi-modal h3{
    color: #00a1ed;
    margin-top: 0;
}
.intruksi-modal p{
    color:#808080;
    font-weight: 500;
}
.card-grid-modal{
    width:100%;
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 40px;
    grid-column-gap: 40px;
    overflow-y: auto;
    /* display: flex;
    gap: 40px;
    overflow-y: auto; */
}
.template-email-keterangan-header p{
    margin:0;
    padding: 10px 0px 10px 20px;
}
.template-email-keterangan-body p{
    margin:0;
    font-size: 12px;
    padding: 5px 0px 5px 20px;
}

.input-kredit{
    width: 50px !important;
    text-align: center; 
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    border:1px solid #cccccc
}
.input-kredit:focus{
    outline-width: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.box-talenta-wrapper{
    padding: 0 0 30px 30px;
    height: 330px;
    /* border: 1px solid; */
}
.box-talenta-container{
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc ;
    width: 100%;
    height: 100%;
    position: relative;
}

.kinerja-text{
    font-weight: bold;
    color: #cccccc;
    margin:0;
    top: 50%;
    left: -20px;
    position: absolute;
    -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}
.potensial-text{
    font-weight: bold;
    color: #cccccc;
    margin:0;
    bottom: -25px;
    left: 40%;
    position: absolute;
}
.box-talenta-grid{
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    padding:0 0 35px 35px ;

}
.box-talenta-grid>div{
    /* border: 1px solid black; */
    height: 77px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px 5px 10px;
    /* border: 1.5px dashed  red; */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D02424FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}
.box-talenta-grid>div:hover{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}
.choose-template{
    border-bottom: 1px solid;
    margin-bottom: 10px;
    padding: 5px;
}
.choose-template:hover{
    background-color: #00a1ed;
    
}
.choose-template:hover p{
    color: white!important;
}
.choosen{
    background-color: #00a1ed;
}
.choosen p{
    color: white!important;
}
@media screen and (max-width:768px){
    .modal-content{
    /* overflow-y: scroll; */

        /* border:1px solid black; */
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal-header h2{
        font-size: 18px;
    }
    .modal{
        align-items: flex-end;
    }
}