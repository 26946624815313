.proyek-info-wrapper{
    display: flex;
    gap: 20px;
}
.proyek-info-wrapper>div{
    border-radius: 7px;
    background-color: white;
    padding: 15px;

  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.proyek-info-wrapper img{
    width: 30px;
}
.proyek-info-wrapper h3{
    font-family: 'Lato', sans-serif;

    margin: 0;
}
.box-company{
    width: 35%;
}
.box-year{
    width: 10%;
}
.box-desc{
    width: 40%;
}
.box-employee{
    width: 20%;
}
.box-title{
    margin:0 0 10px 0;
    font-family: 'Lato', sans-serif;
    color:#252525;
    font-weight: bold;
    font-size: 14px;
}
.desc-content{
    margin: 0;
    font-size: 12px;
    color: #252525;
    font-weight: bold;
}
.card-table__head_btn{
    padding:0px!important;
    background-color: transparent;
    color: #3b99eb;
    cursor: pointer;
    border:0;
    font-weight: bold;
    outline: none;
    margin:10px;
    display: flex;
    align-items: center;
}
.jenis-peta p{
    color: #252525;
}
.jenis-peta:hover{
    background-color: #3b99eb;

}
.jenis-peta:hover p{
    color: white;
}
.peta-active{
    background-color: #3b99eb;
}
.peta-active p{
    color:white
}
.sebaran-wrapper{
    padding: 0 120px 20px 120px;
}

.sebaran-container{
    height: 620px;
    border-left: 1.5px solid #cccccc;
    border-bottom: 1.5px solid #cccccc ;
    position: relative;
}

.box-sebaran-grid{
    display: grid;
    grid-template-columns: repeat( 3,1fr);
    grid-row-gap: 5px;
    grid-column-gap: 5px;
    padding:0 20px 45px 45px ;
    position: relative;
}
.box-sebaran-div{
    /* border: 1px solid black; */
    height: 187px;
    /* cursor: pointer; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #3b99eb; */
    padding: 5px 40px 5px 40px;
    /* border: 1.5px dashed  red; */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D02424FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}
.box-sebaran-div p{
    font-weight:bold;
}
.box-sebaran-div:hover{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}
.box-sebaran-div-dots{
    /* border: 1px solid black; */
    height: 187px;
    /* cursor: pointer; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #3b99eb; */
    padding: 0;
    /* border: 1.5px dashed  red; */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D02424FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}
.box-sebaran-div-dots:hover{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}
.box-sebaran-nama{
    /* border: 1px solid black; */
    height: 190px;
    cursor: pointer;
    position: relative;
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    /* background-color: #3b99eb; */
    /* padding: 5px 48px 5px 48px; */
    /* border: 1.5px dashed  red; */
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D02424FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
    overflow-y: auto;
    overflow-x: hidden;
}
.box-sebaran-nama:hover{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}

.box-sebaran-nama::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* border-radius: 10px; */
	/* background-color: #F5F5F5; */
}

.box-sebaran-nama::-webkit-scrollbar
{
	width: 3px;
	/* background-color: #F5F5F5; */
}

.box-sebaran-nama::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: #bdbdbd;
}

.norm-chip{
    display: inline-block;
  padding:0 20px 0 20px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #f1f1f1;
  margin-right: 10px;
}
[contenteditable] {
    outline: 0px solid transparent;
  }
  [contenteditable=true]:empty:before{
    content: attr(placeholder);
    pointer-events: none;
    display: block; /* For Firefox */
  }
  .pipeline-filterby{
    font-size: 12px;
    margin:3px;
    color: #252525;
}


.dots-item{
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
}