.detail-abk-card-wrapper{
    display: flex;
    gap: 35px;
    
}
.detail-abk-card{
    padding:15px 15px 10px 15px;
    width: 20%;
    /* height: 60px; */
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card-value-abk{
    margin: 0;
    color: #252525;
    font-size: 22px;
}
.card-value-abk-surplus{
    margin: 0;
    /* color: #252525; */
    font-size: 22px;
}
.detail-abk-card-title{
    margin: 0;
    color: #252525;
    font-weight: 500;
    font-size: 13px;
}

.modal-filter-rbk{
    position: absolute;
    width: 130px;
    padding: 10px;
    right: 60px;
    z-index: 2;
    /* border: 1px solid black; */
    background-color: white;
    border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.modal-filter-rbk h2{
    margin: 0;
    color: #252525    ;
}
.backdoor{
    z-index: 1;
    width: 80%;
    position: absolute;
    height: 100vh;
    /* border: 1px solid black; */
    top:0;

}
.backdoor2{
    z-index: 1;
    width: 75%;
    position: absolute;
    height: 100vh;
    /* border: 1px solid black; */
    top:0;
}

.rbk-info-wrapper{
    display: flex;
    justify-content: space-between;
    /* gap: 20px; */
}
.rbk-info-posisi{
    width: 45%;
    /* height: 210px; */
    /* border: 1px solid black; */
    background-color: white;
    box-shadow: 0 3px 10px 0 rgba(37, 37, 37, 0.25);
}
.rbk-small-card{
    width: 53%;
    /* height: 210px; */
    /* border: 1px solid black; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap:20px
}
.rbk-small-top{
    display: flex;
    justify-content: space-between;
}
.rbk-small-top>div{
    min-height: 85px;
}
.rbk-small-top-card{
    width: 31%;
    /* height: 85px; */
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.rbk-small-top-card-workload{
    width: 65%;
    /* height: 85px; */
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.rbk-small-bottom{
    display: flex;
    justify-content: space-between;
}
.rbk-small-sd{
    width: 31%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    height: 85px;

}
.rbk-small-hasil{
    width: 31%;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    height: 85px;

}

.rbk-info-status{
    margin: 0;
    font-weight: bold;
    /* color:#a8e090 */
}
.rbk-info-posisi-item{
    margin-top: 13px;
}
.rbk-info-posisi-item p{
    font-size: 13px;
    font-weight: 500;
    color: #777777;
    margin: 0px 0px 8px 0px;
}
.rbk-info-title{
    margin: 0;
     /* font-weight: bold; */
  color: #252525;
  font-size: 14px;
font-weight: bold;
/* margin:5px; */
}
.rbk-info-title-blue{
    margin: 0;
     /* font-weight: bold; */
  color: #00a1ed;
  font-size: 13px;
font-weight: bold;
cursor: pointer;
/* margin:5px; */
}

.chevron-down{
    width: 19px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-radius: 3px;
    margin-bottom:8px ;
    margin-left: 10px;
    cursor: pointer;
}
.chevron-down img{
    width: 10px;
}
.rb-info-content h2{
    margin: 0;
    font-weight: 500;
}
.rb-info-content p{
    margin: 0;
    font-size: 12px;
    color: #252525;
    font-weight: 400;
}
.rbk-info-fte{
    font-size: 12px;
    margin: 0;
    color: #252525;
}

.position-relative{
    position: relative;
}
.responden-modal{
    position: absolute;
    width: 200px;
    /* height: 100px; */
    right: 25px;
    top: 0;
    z-index: 2;

    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgb(119 119 119 / 35%);
}
.responden-modal-item{
    padding: 10px;
    cursor: pointer;
}
.responden-modal-item h4{
    margin: 0;
    font-weight: 500;
    color: #252525    ;
}
.responden-modal-item:hover {
    background-color: #00a1ed;
    color: white;
}
.responden-modal-item-active{
    background-color: #00a1ed;
}
.responden-modal-item-active h4{
    color: white;
}
.responden-modal-item:hover h4{
    color: white;
}
.wke-modal-item{
    padding: 10px;
    cursor: pointer;
}
.wke-modal-item h5{
    margin: 0;
    font-weight: 500;
    color: #252525 ;
}
.wke-modal-item:hover{
    background-color: #00a1ed;
}
.wke-modal-item:hover h5{
    color: white;
}

.wke-modal-item-active{
    background-color: #00a1ed;
}
.wke-modal-item-active h5{
    color: white;
}
.custom-hr2{
    width: 100%;
    height: 0.5px;
    background-color: #eeeeee;
}
.custom-hr{
    width: 100%;
    height: 0.5px;
    background-color: #6a7267;
}
.wke-modal{
    position: absolute;
    width: 200px;
    /* height: 100px; */
    right: 25px;
    z-index: 2;

    top: 0;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgb(119 119 119 / 35%);
}
.wke-modal h3{
    margin: 0;
    color: #252525    ;
}

.batasfte-modal{
    position: absolute;
    width: 250px;
    /* height: 100px; */
    right: 25px;
    z-index: 2;
    top: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgb(119 119 119 / 35%);
}

.hirarki-title{
    margin: 0;
    /* font-weight: bold; */
    color: #252525;
    font-size: 16px;
    font-weight: bold;
    /* margin: 5px;*/
}
.hirarki-urutan{
    margin: 0;
    /* font-weight: bold; */
    color: #252525;
    font-size: 14px;
    font-weight: bold;
    /* margin: 5px;*/
}
.hirarki-wrapper{
    margin-top: 20px;
}
.hirarki-top{
    width: 100%;
    background-color: #ccecfc;
}

.hirarki-text-item{
    margin: 0;
    color: #252525;
    font-weight: 500;
    font-size: 13px;
}

.hirarki-text-padding{
    padding: 7px 7px 7px 13px;
}

.hirarki-icon{
    width: 17px;
    cursor: pointer;
    margin: 0px 0px 0px 20px;
}

.hirarki-number{
    margin:0px 5px 0px 0px;
    color: #252525;
    font-weight: 500;
    font-size: 13px;
}

.content-sub{
    margin:10px 0px 0px 45px;
}
.content-sub2{
    margin:0px 0px 0px 40px;
}

.btn-tambah-detail-aktivitas{
    width: 100%;
    height: 40px;
    /* border: 1px dashed black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee    ;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23777777FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.btn-tambah-detail-aktivitas p{
    margin: 0;
    font-weight: bold;
    color: #252525;
}

.btn-tambah-tugas-utama{
    width: 100%;
    height: 40px;
    /* border: 1px dashed black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccecfc    ;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2300A1EDFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.btn-tambah-tugas-utama p{
    margin: 0;
    font-weight: bold;
    color: #252525;
}

.tugas-utama-modal{
    display: flex;
    position: absolute;
    width: 500px;
    /* height: 100px; */
    /* right: 25px; */
    top: 0;
    right: -80px;
    left: 0;
    z-index: 2;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgb(119 119 119 / 35%);
}
.detail-tugas-utama-modal{
    display: flex;
    position: absolute;
    width: 500px;
    /* height: 100px; */
    left:0;
    top: 0;
    z-index: 2;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgb(119 119 119 / 35%);
}

.chevron-hirarki{
    overflow: hidden;
  transition-duration: 0.5s;
  transition-property: transform;
  width: 13px;
  margin-right: 20px;
  cursor: pointer;
}
.chevron-hirarki-active{
    transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.hirarki-container{
    margin-bottom: 30px;
}

.rbk-info-workload p{
    font-size: 12px;
    font-weight: 500;
    color: #777777;
    margin: 0px 0px 3px 0px;
}
.need-sdm-info p{
    font-size: 10px;
    font-weight: 500;
    color: #777777;
    margin: 0px 0px 3px 0px;
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    /* border-color: #777777!important; */
}
.MuiInputBase-input.Mui-disabled {
    /* color: #777777!important; */
}

.hihi{
    /* border-radius: 20px!important; */
}