.welcome-wrapper{
    display: flex;
    width: 90%;
    margin: auto;
    height: 99vh;
    align-items: center;
    /* border:1px solid black */
}

.welcome-text{
    /* border:1px solid red; */
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.welcome-example{
    /* border:1px solid blue; */
    width: 60%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.shape-img{
    width: 100px;
    position: absolute;
}
.welcome-text h2{
    font-size: 20px;
    font-weight: normal;
    color: #808080;
}
.welcome-text span{
    font-weight: bold;
}
.welcome-text p{
    color: #808080;
    margin:30px 0px 30px 0px!important;
}

.welcome-example{
    padding: 20px;
    display: flex;
    align-items: center;
}
.card-example{
    width: 100%;
    max-height: 510px;
    border-radius: 20px;
    box-shadow: 0 0 30px 0 rgba(37, 37, 37, 0.25);
    background-color: white;
    z-index: 2;
    overflow-y: auto;
    overflow-x: hidden;
}
.card-example::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* border-radius: 10px; */
	/* background-color: #F5F5F5; */
}

.card-example::-webkit-scrollbar
{
	width: 6px;
	/* background-color: #F5F5F5; */
}

.card-example::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3); */
	background-color: #bdbdbd;
}
.card-example{
    padding: 20px;

}
.card-example h3{
    color: #00a1ed;
}
.card-example p{
    color:#808080;
    font-weight: 500;
}



.show-mobile{
    display: none;
}

.navbar-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    position: fixed;
    top:0;
    z-index: 999;
}
.navbar-wrapper h3{
    /* font-weight: normal; */
    color: #00a1ed;
    margin:0;
}
.navbar-wrapper .name{
    font-weight: bold;
}
.navbar-wrapper .timer{
    font-weight: bold;
    color: #ffb865;
}
.timer-expired{
    font-weight: bold;
    color: red;
}

.navbar{
    width: 80%;
    margin: auto;
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-content{
    width:400px;
    outline: 0px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    /* overflow-y: scroll; */
}
/* .modal-body{ */
    /* padding:10px; */
    /* padding-left:20px;
    padding-right:20px;
    padding-top:0px; */
/* } */
.expired-wrapper{
    text-align: center;
    padding:10px

}

.info-wrapper{
    padding:10px;

}
.info-flex{
    display: flex;
}
.info-wrapper h2{
    color:#00a1ed;
    margin:0;
    margin-bottom: 10px;
}
.info-data-diri{
    width:50%;
}
.info-kontak{
    width:50%;
    padding-left: 20px;
}
.question-flex{
    display: flex;
}
.footer-form-mobile{
    display: none!important;
}

.chevron-contoh{
    width: 15px;
    cursor: pointer;
}
.image-contoh{
    width: 70%;
    
}
.instruksi-text{
    font-size: 16px;
    margin:0
}
.slider-contoh{
    overflow-y: hidden;
    overflow-y: auto;
}
.card-grid-contoh{
    width: 300px;
}
.circle-help{
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
}
.circle-help span{
    font-weight: bold;
    color: #00a1ed;
}
.assestmen-toolbar{
    width: 360px;
}
.assestment-container{
    width: 65%;
    margin:auto;
}
.assestment-container-h4{
    margin:0!important;
    color: #808080;
}
.assestment-container-p{
    margin:0!important;
    color: #808080;
}
.assestment-question{
    display: flex;
    position: relative;
    /* justify-content: center; */
    /* text-align: center; */
}
.circle-number{
    width: 23px;
    height:  23px;
    border:2px solid black;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: absolute;
}
.assestment-image{
    width: 60%;
}
.assestment-answer{
    display: flex;
    flex-wrap: wrap;
    /* margin-right: 20px; */
    /* gap: 22px!important; */
    /* grid-gap: 21px!important; */
}
.assestment-subimage{
    width: 100%;
    margin-bottom: 5px;
    cursor: pointer;
}
.assestment-answer-item{
    /* width:115px; */
    text-align: center;
    margin-right: 20px;
    margin-bottom: 20px;
}
.question-wrapper{
    margin-left: 30px;
}


.responden-form-title{
    font-weight: bold;
    color: #777777;
    font-size: 16px;
}
@media screen and (max-width:768px){
    
    .assestment-container{
        width: 90%;
    }
    .assestment-container-h4{
        margin-bottom: 10px!important;
    }
    .assestment-container p{
        font-weight: 500;

    }
    .assestment-container-h4{
        font-size: 22px!important;
        color: #808080;
    }
    .assestment-intruksi{
        font-size: 22px!important;
        color: #808080;
    }
    .assestment-question{
        flex-direction: column;
    }
    .question-wrapper{
        margin:40px 0px 0px 0px;
    }
    .circle-number{
        width: 30px;
        height: 30px;
        border:3px solid black;
        font-size: 24px;
    }
    .assestment-image{
        width: 100%;
    }
    .assestment-answer-item{
        width: 29%;
        margin-right: 10px;
    }
    .assestmen-toolbar{
        width: 160px;
        flex-direction: row-reverse;
        margin-right: 10px;
    }
    .instruksi-text{
        font-size: 18px;
    }
    .image-contoh{
        width: 80%;
    }
    .footer-form-mobile{
        display: flex!important;
    }
    .info-wrapper{
        padding:20px
    }
    .question-flex{
        flex-direction: column;
    }
    .assestment-wrapper{
        background: white;
    }
    .info-data-diri{
        width:100%
    }
    .info-kontak{
        width: 100%;
        padding-left: 0px;
    }
    .info-flex{
        flex-direction: column;
    }
    .welcome-wrapper{
        flex-direction: column;
        width: 100%;
        /* border:1px solid black; */
        height: -webkit-fit-content;
    }
    .show-mobile{
        display: block;
    }
    .welcome-text{
        width: 100%;
        min-height: 230px;
        /* border:1px solid red; */

    }
    .welcome-mobile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
        /* height: 100vh; */
        padding: 10px 20px 10px 20px;
    }
    .welcome-example{
        width: 100%;
        padding: 0px;
        /* border:1px solid green; */
        /* min-height: 750px; */
    }
    .hide-mobile{
        display: none!important;

    }
    .card-example{
        padding:0px;
        border-radius: 25px;
        max-height: unset;
        overflow-y: hidden;
        /* padding: 10px 20px 10px 20px; */
        
    }
    .card-example h3{
        font-size:21px;
    }
    .card-example p{
        font-weight: normal;
    }
    .shape-img{
        display: none;
    }
    .card-grid{
        width:100%;
        /* border:1px solid red; */
        overflow-y: hidden;
        overflow-x: scroll;
        grid-column-gap: 20px;
        grid-template-columns: repeat(3,2fr)
    }
    .card-grid-assestment{
        width: 200px;
    }
    .assestment-grid{
        width: 95%;
        margin: auto;
        grid-template-columns: repeat( 1,minmax(10rem,1fr));

    }
    .navbar-wrapper h3{
        margin: 0;
    }
    .navbar{
        width: 95%;
        justify-content: space-between;
    }
    .modal-content{
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal{
        align-items: flex-end;
    }
    .grid-head p{
        margin: 10px 10px 10px 5px;
    }
    .square{
        width: 30px;
        height: 30px;
        margin-top: 0px;
    }
    .welcome-text h2{
        font-size: 24px;
        font-weight: normal;
        color: #00a1ed;
        margin:20px 0px 0px 0px;
    }
    .welcome-text p{
        color: #808080;
        margin:10px 0px 10px 0px!important;
        font-size: 18px;
    }
}