nav{
    width:100%;
    height: 64px;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    z-index: 999;
    position: fixed;
    top:0;
    align-items: center;
    padding: 0 50px 0 30px;
}
.logo{
    width:250px;
    /* border:1px solid red; */
    align-self: center;
    /* justify-content: space-around; */
    /* align-items: center; */
    display: flex;
    cursor: pointer;
    margin-left: 20px;
}
.logo img{
    width: 100%;
}

.vertical-line{
    width:2px;
    height: 50px;
    background-color: #ccc;
}
.logo img{
    width: 40px;
}
.logo h4{
    color: rgb(128, 128, 128);
    font-size: 20px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.06px;
}
.profile{
    width: 170px;
    cursor: pointer;
    /* border:1px solid green; */
    /* text-align: center; */
}

.hamburger{
    width:50px;
    /* border:1px solid black; */
    height: 40px;
    background-color: transparent;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.hamburger:hover{
    background-color: white;
}
.hamburger__list{
    width: 40px;
    height: 2px;
    background-color: black;
}

.search{
    width: 790px;
    border-radius: 4px;
    align-items: center;
    padding: 2px;
    display: flex;
    border-radius: 34px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: #ffffff;
}
.search img{
    margin-left: 10px;
    margin-right: 10px;
    width: 20px;
}
.search-field{
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.05px
}
@media screen and (max-width:768px){
    .hide-mobile{
        display: none;
    }
    .hamburger{
        display: flex;
    }
    nav{
        height: 100px;
        
    }
    .logo{
        align-content: center;
        border:1px solid black;
    }
}