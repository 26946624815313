
.login-wrapper{
    height: 95vh;
    border:1px solid white;
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:80%;
    margin: auto;
}
.login-form{
    width:40%
    /* width: 400px;
    height: 430px;
    margin-left: 99px;
    margin-top: 120px; */
}
.img-bg{
    width: 50%;
}
.img-bg img{
    width:100%
}
.login-form h1{
    color: #65b7ff;
    font-family: 'Open Sans', sans-serif;

}
.login-form p{
    color: #808080;
}
.login-form h3{
    font-size: 24px;
    color: #808080;
    margin-top: 10px;
}
.form-login{
    margin-bottom: 20px!important;
    width: 260px;
  /* height: 40px!important; */

}
.form-login2{
    margin-bottom: 20px!important;
    width: 100%;
  height: 40px!important;

}
.btn-login{
    font-weight: bold!important;

    text-transform: none!important;
    width:260px;
    border-radius: 24px!important;
    box-shadow: 0 3px 6px 0 rgba(0, 161, 237, 0.25);

}
.img-bg-mobile{
    display: none;
}
@media screen and (max-width:768px){
    .login-wrapper{
    margin-bottom: 50px;

    }
    .img-bg{
        display: none;
    }
    .login-form{
        width: 100%;
        text-align: center;
        margin-top: 80px;
    }
    .form-login{
        width: 100%;
        margin-bottom: 30px;
    }
    .btn-login{
        width: 100%;
        font-weight: bold!important;
    }
    .img-bg-mobile{
        display: block;
        width: 100%;
    }
    .login-form h1{
        font-size: 20px;
        margin-top: 20px;
    }
}