body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn-remove-capital{
  text-transform: none!important;
  font-weight: bold!important;
  min-width: 100px!important;
  height: 30px!important;
}
.btn-rounded{
  border-radius: 20px!important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)!important;
}
.btn-rounded2{
  border-radius: 20px!important;
  box-shadow: 0 3px 6px 0 rgba(168, 224, 144, 0.35)!important;
}
.remove-capital{
  text-transform: none!important;
  font-weight: 600!important;
}
.icon-table{
  width:16px ;
  margin-left: 24px;
  cursor: pointer;
}
.remove-boxshadow{
  font-weight: 600!important;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
  /* box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important; */

}
.div-flex-column{
  display: flex;
    flex-direction: column;
}
.div-flex{
  display: flex;
}
.div-space-between{
  justify-content: space-between;
}
.div-align-center{
  align-items: center;
  /* border:1px solid black; */
}
.div-align-start{
  align-items: flex-start;
  /* border:1px solid black; */
}
.div-justify-center{
  justify-content: center;
}
.semi-bold{
  /* font-weight: bold; */
  color: #777777;
  font-size: 18px;
font-weight: 600;
  margin-top:0;
  font-size: 14px;
/* margin:5px; */
}

.semi-bold-without-margin{
  /* font-weight: bold; */
  color: #777777;
  font-size: 18px;
font-weight: 600;
  margin-top:0;
  margin-bottom:0;
  font-size: 14px;
font-weight: 600;
/* margin:5px; */
}
.semi-bold2{
  /* font-weight: bold; */
  color: #777777;
font-weight: 600;
  margin-top:0;
  font-size: 10px!important;
font-weight: 600;
/* margin:5px; */
}

.btn-action{
  min-width:100px!important;
  margin:0px 10px 0px 10px!important;
  height: 30px!important;
}

.btn-no-padding{
  padding:0!important;
  margin: 0!important;
}
.btn-text{
  height:30px!important
}
.icon-action{
  width:15px;
  height: 15px;
  margin:5px 2px 5px 10px;
  cursor: pointer;
}
.btn-outlined{
  background-color: #FFF!important;
 
}