.sidebar-wrapper{
  width:220px;
  height: 100vh;
  position: fixed;
  background: url('../../assets/image/sidebar.jpg');
  background-repeat: no-repeat;
  background-size:100% 100%;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  padding-top: 90px;
  /* animation: movingsidebar 0.25s ease-in-out; */
  
}
/* .sidebar-wrapper:after{
  left:0; top:0;bottom:0;
  z-index: -1;
  position: absolute;
  content: '';
  width: 250px;
  height: 100%;
  background-color: rgba(37, 37, 37, 0.8);
} */
.sidebar-wrapper a{
  font-weight: bold;
}
@keyframes movingsidebar{
  from{
      width:0px;
  }to{
      width: 250px;
  }
}
/* .list-sidebar a{
  margin: 10px 0px 15px 25px;
  padding:5px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 8px;
} */
.list-sidebar{
  margin: 10px 10px 15px 25px;
  /* height: 30px; */
  padding:5px 15px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.sidebar-wrapper .hr{
  /* color: green; */
  width: 230px;
  height: 1px;
  margin-left: 20px;
  margin-bottom: 20px;
  background-color: white;
}

.is-collapse{
  margin: 10px 10px 15px 25px;
  /* height: 30px; */
  padding:5px 15px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.is-collapse:hover{
  color: #f1f1f1;
  background-color: #e1e7ea;
}
.is-active-menu{
  background-color: #e1e7ea;
}
.is-active-menu a{
  color: #00a1ed!important;

}
.admin-list:hover{
  color:#00a1ed!important; ;
}
.content-collapsee{
  width: 70%;
  margin: 0px 0px 15px 75px;
}
.list-collapse a{
  /* margin: 20px 0px 0px 0px; */
  margin-bottom: 5px;
  /* height: 30px; */
  padding:5px 10px 5px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 20px;

}
.list-collapse-active{
  color: #f1f1f1;
  background-color: #e1e7ea;
  border-radius: 8px;
}
.list-sidebar:hover, .list-collapse a:hover{
  color: #00a1ed!important;
  background-color: #e1e7ea;
}
/* .list-sidebar a:hover{
  color: #00a1ed;

} */
.backdrop-wrapper{
  width: 100%;
  height: 100vh;
  /* border:1px solid green */
}
.chevron{
  width: 15px;
  height: 20px;
  transition: 0.70s;
}
.active-chevron{
  width: 15px;
  cursor: pointer;
  transition: 0.70s;
  transform: rotate(180deg);
}

.icon-size{
  width: 25px;
}
.ava-size{
  width: 15px;
}
.rounded-avatar{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #e6e8e6;
}
@media screen and (max-width:768px){
  .sidebar-wrapper{
      /* display: none ; */
      z-index: 3;
  }
}