.head-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-left: 20px; */
    /* border:1px solid black */
    margin-bottom: 20px;
}
.head-section h4{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
    margin: 0;
}
.card-content{
    /* min-height: 50vh; */
    /* border-radius: 10px; */
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    
    display: flex;
    flex-direction: column;
}

.card-table{
    /* margin-top: 20px; */
    padding:20px

}
.card-table__head{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-table__pagination{
    display: flex;
    justify-content: flex-end;
}